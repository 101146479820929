import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { ThemeContext } from "../../App";

export default function Report({ reportId, embedUrl }) {
  const { setPbiToken } = useContext(ThemeContext);
  const containerRef = useRef();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (containerRef.current) {
      const containerElement = containerRef.current;
      if (!isFullScreen) {
        if (containerElement.requestFullscreen) {
          containerElement.requestFullscreen();
        } else if (containerElement.mozRequestFullScreen) { /* Firefox */
          containerElement.mozRequestFullScreen();
        } else if (containerElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          containerElement.webkitRequestFullscreen();
        } else if (containerElement.msRequestFullscreen) { /* IE/Edge */
          containerElement.msRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };

  return (
    <Container ref={containerRef}>
      <div className="btnContainer">
        <button className="btnMaximizar" onClick={handleFullScreen}>
          { isFullScreen ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
        </button>
      </div>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: reportId,
          embedUrl: embedUrl,
          accessToken: localStorage.getItem('pbiToken'),
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: { expanded: false, visible: false },
              pageNavigation: { visible: true },
            },
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth
            }
          }
        }}
        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) {
              localStorage.removeItem('pbiToken');
              setPbiToken('');
            }],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }
        cssClassName={"Embed-container"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 99%;
  width: 100%;
  .btnContainer {
    position: relative;
    .btnMaximizar {
      position: absolute;
      right: 0;
      bottom: -10;
      background-color: transparent;
      border: 0;
      padding: 3px 10px 0 0;
      cursor: pointer;
      color: ${(props) => props.theme.gray500};
    }
  }
  .Embed-container {
    height: 100%;
  }
`;