import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataGrid } from '@mui/x-data-grid';
import { Chip, IconButton, Snackbar, Alert } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { getUserList } from "../api/users";
import { ThemeContext } from "../App";
import { getUserDetails } from "../api/users";
import User from "../components/ManageUsers/NewEditUser";

export default function Settings() {

  const [usersList, setUsersList] = useState([]);
  const { user } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [response, setResponse] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const listUsers = () => {
    getUserList().then((res) => {
      const sortedList = res.toSorted((a, b) => a.id - b.id);
      setUsersList(sortedList);
    });
  };

  const handleOpen = (id) => {
    setOpen(true);
    getUserDetails(id).then((res) => {
      setUserDetails(res);
    });
  };

  const handleClose = () => {
    setOpen(false);
    setUserDetails([]);
  };

  useEffect(() => {
    if (user.userrole === 'admin') {
      listUsers();
    }
  }, []);

  useEffect(() => {
    if (response && response?.updateInfo) {
      listUsers();
    }
  }, [response]);


  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    {
      field: 'username',
      headerName: 'Usuario',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 0.15,
      editable: false,
    },
    {
      field: 'lastname',
      headerName: 'Apellido',
      flex: 0.15,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.3,
      editable: false,
    },
    {
      field: 'userrole',
      headerName: 'Perfil',
      flex: 0.1,
      renderCell: (params) => (
        <Chip label={params.value} variant="outlined" size="small" className={`chip ${params.value}`} />
      ),
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params) => (
        <div className="buttonContainer">
          <IconButton color="warning" aria-label="edit user" component="span" onClick={() => handleOpen(params.row.id)}>
            <EditIcon />
          </IconButton>
        </div>
      ),
      editable: false,
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <Container>
      <h1>Settings</h1>
      {
        user?.userrole === 'admin' ? (
          <div className="table-section">
            <DataGrid
              rows={usersList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              className="my-grid"
            />
            <div className="floatButton">
              <button onClick={() => handleOpen(null)}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> Nuevo Usuario
                </span>
              </button>
            </div>
            <User
              userDetails={userDetails}
              handleClose={handleClose}
              open={open}
              setResponse={setResponse}
              setOpenSnackbar={setOpenSnackbar}
            />
          </div>
        ) : (
          <div style={{ paddingTop: '20px' }}>
            <h4>No tiene permisos para acceder a esta vista</h4>
          </div>
        )
      }
      
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={response.severity} sx={{ width: '100%' }}>
          {response.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 99%;
  padding: 20px 40px 0px 40px;
  box-sizing: border-box;

  .table-section {
    display: flex;
    margin-top: 20px;

    .my-grid {
      background-color: ${(props) => props.theme.bg2};
      .MuiDataGrid-cell {
        color: ${(props) => props.theme.text};
      }
      .MuiDataGrid-columnHeader {
        color: ${(props) => props.theme.text};
      }
      .MuiDataGrid-toolbarContainer {
        color: ${(props) => props.theme.text};
      }
      .MuiDataGrid-footerContainer {
        color: ${(props) => props.theme.text};
      }
      .MuiDataGrid-footer {
        color: ${(props) => props.theme.text};
      }
      .MuiDataGrid-iconSeparator {
        color: ${(props) => props.theme.text};
      }
      .MuiIconButton-root {
        color: ${(props) => props.theme.text};
      }
      .MuiTablePagination-displayedRows {
        color: ${(props) => props.theme.text};
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .chip {
      border: none;
    }

    .common {
      background-color: #7ED9E7;
      color: #616161;
    }

    .admin {
      background-color: #1DC040;
      color: white;
    }

    .floatButton {
      position: fixed;
      bottom: 20px;
      right: 20px;
      button {
        border: 2px solid ${(props) => props.theme.bg4};
        background-color: ${(props) => props.theme.bg4};
        border-radius: 0.9em;
        padding: 0.8em 1.2em 0.8em 1em;
        transition: all ease-in-out 0.2s;
        font-size: 16px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 600;
        } 
      }
      button:hover {
        border: 2px solid ${(props) => props.theme.bghover};
        background-color: ${(props) => props.theme.bghover};
      }
    }
  }
`;