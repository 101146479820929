import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumbs, IconButton, Link, Menu, MenuItem, Snackbar, Alert,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from "styled-components";
import { deleteItem, getEmbedUrl, newItem, updateItem } from "../api/pbi";
import { getItemsByUser } from "../api/auth";
import chart1 from "../assets/img/chart1.gif";
import Report from "../components/PowerBi/PowerBi";
import { ThemeContext } from "../App";
import Item from "../components/ManageItems/NewEditItem";
import CustomDelete from "../components/ManageItems/DeleteItem";

export default function Dashboards() {
  const { pbiToken, user } = useContext(ThemeContext);
  const [items, setItems] = useState([]);
  const [selectedReport, setSelectedReport] = useState('');
  const [embedURL, setEmbedURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [route, setRoute] = useState([]);
  const handleLinkClick = async (event, item) => {
    event.preventDefault();
    setRoute([...route, item.name]);
    if (!item.isfolder) {
      setSelectedReport(item.reportid);
      const obj = {
        token: pbiToken,
        reportId: item.reportid,
      };
      try {
        const response = await getEmbedUrl(obj);
        if (!response.ok) {
          throw embedURL;
        }
        setEmbedURL(response.embedURL);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const findItem = (array, route) => {
    for (let item of array) {
      if (item.name === route[0]) {
        if (route.length === 1) {
          return item;
        } else {
          return findItem(item.subitems, route.slice(1));
        }
      }
    }
    return null;
  };

  const handleLinkBack = (event, index) => {
    event.preventDefault();
    setRoute(route.slice(0, index + 1));
    if (route[index] !== currentItem.name) {
      setSelectedReport('');
      setEmbedURL('');
    }
  };
  let currentItem = findItem(items, route);


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const obj = { idUser: user.id, userRole: user.userrole };
      const responseData = await getItemsByUser(obj);
      const itemMap = {};
      responseData.forEach((item) => {
        if (item.isfolder) {
          itemMap[item.iditem] = { ...item, subitems: [] };
        } else {
          itemMap[item.iditem] = { ...item };
        }
      });
      const items = [];
      responseData.forEach((item) => {
        if (item.parentid === null) {
          items.push(itemMap[item.iditem]);
        } else {
          itemMap[item.parentid].subitems.push(itemMap[item.iditem]);
        }
      });
      setItems(items);
      setRoute([items[0].name]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      fetchData();
    }
  }, [user]);

  // Modal Item
  const [open, setOpen] = useState(false);
  const [titleManageItem, setTitleManageItem] = useState('Agregar');
  const [name, setName] = useState('');
  const [isFolder, setIsFolder] = useState(true);
  const [reportId, setReportId] = useState('');
  const [loadingNew, setLoadingNew] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    clearInputs();
    setOpen(false);
  }
  const clearInputs = () => {
    setIsFolder(true);
    setReportId('');
    setName('');
    setSelectedItem('');
    setTitleManageItem('Agregar');
  };
  const handleNewItem = async (item) => {
    setLoadingNew(true);
    const obj = {
      name,
      parentId: currentItem.id,
      isFolder,
      reportId,
      id: item?.id,
    };
    try {
      const response = !item ? await newItem(obj) : await updateItem(obj);
      if (!response.ok) throw new Error(response.Message);
      setAlertType('success');
      setAlertMessage(`Reporte ${!item ? 'creado' : 'actualizado'} exitosamente`);
    } catch (error) {
      setAlertType('error');
      setAlertMessage(error.message);
    } finally {
      setLoadingNew(false);
      setOpenAlert(true);
      clearInputs();
    }
    fetchData();
  };
  const handleEditClick = async (event, item) => {
    event.stopPropagation();
    setTitleManageItem('Actualizar');
    setIsFolder(item.isfolder);
    setName(item.name);
    setReportId(item.reportid);
    setSelectedItem(item);
    handleOpen();
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => {
    clearInputs();
    setOpenDeleteDialog(false);
    setAnchorEl(null);
  }
  const handleDeleteClick = async (event, item) => {
    event.stopPropagation();
    setSelectedItem(item);
    handleOpenDeleteDialog();
  };
  const handleDeleteItem = async (item) => {
    setLoadingNew(true);
    const obj = { id: item?.id };
    try {
      const response = await deleteItem(obj);
      if (!response.ok) throw new Error(response.Message);
      setAlertType('success');
      setAlertMessage(`Reporte eliminado exitosamente`);
    } catch (error) {
      setAlertType('error');
      setAlertMessage(error.message);
    } finally {
      setLoadingNew(false);
      setOpenAlert(true);
      handleCloseDeleteDialog();
    }
    fetchData();
  };

  // Menu Item
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // Alert
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Container>
      <h1>Dashboards</h1>
      <div className="breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb">
          {route.map((name, index) => (
            <Link className="link" href="#" onClick={(event) => handleLinkBack(event, index)}>
              {name}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
      <div className="cardsContainer">
        {
          items.length > 0 ? (
            <>
              {
                embedURL === '' ? (
                  <div className="cards">
                    {
                      currentItem && currentItem.subitems?.map((subitem) => (
                        <div class="card" onClick={(event) => handleLinkClick(event, subitem)}>
                          <div className="card_header">
                            <span>{subitem.name}</span>
                            <IconButton onClick={handleMenuClick}>
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={(event) => handleEditClick(event, subitem)}>Editar</MenuItem>
                              <MenuItem onClick={(event) => handleDeleteClick(event, subitem)}>Eliminar</MenuItem>
                            </Menu>
                          </div>
                          <div class="card_items">
                            {
                              subitem.isfolder ? (
                                subitem.subitems.map((i) => (
                                  <p class="element">{i.name}</p>
                                ))
                              ) : (
                                <img src={chart1} alt="GIF" />
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <div className="report">
                    {
                      embedURL !== '' && (
                        <Report
                          embedUrl={embedURL}
                          reportId={selectedReport}
                        />
                      )
                    }
                  </div>
                )
              }
            </>
          ) : (
            <span>No data to show</span>
          )
        }
        {
          user && (user.userrole === 'admin' && embedURL === '') && (
            <>
              <div className="floatButton">
                <button onClick={handleOpen}>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> Create
                  </span>
                </button>
              </div>
              <Item
                open={open}
                handleClose={handleClose}
                title={titleManageItem}
                name={name}
                setName={setName}
                isFolder={isFolder}
                setIsFolder={setIsFolder}
                reportId={reportId}
                setReportId={setReportId}
                action={() => handleNewItem(selectedItem)}
                loading={loadingNew}
              />
              <CustomDelete
                open={openDeleteDialog}
                handleClose={handleCloseDeleteDialog}
                action={() => handleDeleteItem(selectedItem)}
                title={`Eliminar ${selectedItem?.isfolder ? 'carpeta' : 'reporte'}`}
                element={selectedItem?.name}
              />
              <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                  {alertMessage}
                </Alert>
              </Snackbar>
            </>
          )
        }
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  padding: 20px 40px 0px 40px;
  box-sizing: border-box;

  .breadcrumbs {
    padding: 0 0 20px 0;
    .link {
      color: ${(props) => props.theme.text};
      text-decoration: none;
    }
  }

  .cardsContainer {
    padding: 0px 0px 0px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: hidden;
    box-sizing: border-box;
  
    &::-webkit-scrollbar {
      display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Edge */
    }
    .cards {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .card {
        width: 250px;
        height: 290px;
        border-radius: 15px;
        background: ${(props) => props.theme.bg};
        color: ${(props) => props.theme.text};
        font-weight: 600;
        font-size: 1.2em;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: -5px 5px 1px 0px ${(props) => props.theme.bg4};
        cursor: pointer;
        &_header {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: center;
          justify-content: space-between;
        }
        &_items {
          .element {
            color: grey;
            font-size: .8em;
            padding: 6px 15px;
            border-left: 2px solid grey;
            cursor: pointer;
          }
          .element:hover:not(.active) {
            color: ${(props) => props.theme.bg4};
          }
          img {
            height: 250px;
            width: 250px;
          }
        }
      }
    }
    .report {
      height: 100%;
      width: 100%;
    }
    .floatButton {
      position: fixed;
      bottom: 20px;
      right: 20px;
      button {
        border: 2px solid ${(props) => props.theme.bg4};
        background-color: ${(props) => props.theme.bg4};
        border-radius: 0.9em;
        padding: 0.8em 1.2em 0.8em 1em;
        transition: all ease-in-out 0.2s;
        font-size: 16px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 600;
        } 
      }
      button:hover {
        border: 2px solid ${(props) => props.theme.bghover};
        background-color: ${(props) => props.theme.bghover};
      }
    }
  }
`;
