import React, { useContext, useState } from "react";
import styled from "styled-components";
import background from "../assets/img/background.jpg";
import { CircularProgress } from "@mui/material";
import { login } from "../api/auth";
import { ThemeContext } from "../App";

export default function Login({ }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loadingLogin, setLoadingLogin] = useState(false);

  const { setPbiToken, setUser } = useContext(ThemeContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingLogin(true);
    let newErrors = {};
    if (!username) newErrors.username = 'El usuario es obligatorio';
    if (!password) newErrors.password = 'La contraseña es obligatoria';
    let errorsLen = Object.keys(newErrors).length;
    if (errorsLen !== 0) {
      setErrors(newErrors);
      setLoadingLogin(false);
    }
    else {
      const obj = { username, password };
      try {
        const response = await login(obj);
        if (!response.ok) newErrors.authentication = response?.Message || response?.message;
        else {
          localStorage.setItem('pbiToken', response.pbiToken);
          setPbiToken(response.pbiToken);
          localStorage.setItem('jwtToken', response.accessToken);
          setUser(response.user);
        }
      } catch (err) {
        newErrors.authentication = err;
      } finally {
        errorsLen = Object.keys(newErrors).length;
        if (errorsLen !== 0) setErrors(newErrors);
        setLoadingLogin(false);
      }
    }
  };

  return (
    <Container backgroundImage={background}>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <h1>Welcome</h1>
          <div className="input-box">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
            />
          </div>
          <div className="errors" style={{ display: Object.keys(errors).length > 0 ? `flex` : `none` }}>
            <ul>
              {
                Object.values(errors).map((e) => (
                  <li>{e}</li>
                ))
              }
            </ul>
          </div>
          <div className="remember-forgot">
            <label><input type="checkbox" /> Remember me</label>
            <a href="#">Forgot password?</a>
          </div>
          <button type="submit" className="btn">
            { !loadingLogin ? (<>Login</>) : (<CircularProgress size="25px" color="inherit" />) }
          </button>
        </form>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-size: cover;
  background-position: center;
  .wrapper {
    width: 420px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
    h1 {
      font-size: 36px;
      text-align: center;
    }
    .input-box {
      width: 100%;
      height: 50px;
      margin: 30px 0;
      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border: 2px solid rgba(255, 255, 255, .2);
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px 45px 20px 20px;
      }
      input::placeholder {
        color: #fff;
      }
    }
    .errors {
      width: 100%;
      height: auto;
      justify-content: flex-start;
      ul {
        margin: 0px 20px 30px 20px;
      }
    }
    .remember-forgot {
      display: flex;
      justify-content: space-between;
      font-size: 14.5px;
      margin: -15px 0 15px;

      label input {
        accent-color: #fff;
        margin-right: 3px;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .btn {
      width: 100%;
      height: 45px;
      background-color: #fff;
      border: none;
      outline: none;
      border-radius: 40px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      cursor: pointer;
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }
    .register-link {
      font-size: 14.5px;
      text-align: center;
      margin: 20px 0 15px;
      p a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
      }
      p a:hover {
        text-decoration: underline;
      }
    }
  }
`;
