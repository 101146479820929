import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AiOutlineLeft, AiOutlineHome, AiOutlinePieChart } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import logoLight from '../../assets/img/img-logo-white.png';
import logoDark from '../../assets/img/img-logo-black.png';
import { v } from '../../styles/Variables';
import { ThemeContext } from "../../App";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const ChangeSidebarState = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const { theme, setTheme, setIsAuthenticated, setJwtToken, setPbiToken } = useContext(ThemeContext);
  const ChangeTheme = () => setTheme((theme) => theme === 'light' ? 'dark' : 'light');
  const logout = () => {
    localStorage.removeItem('jwtToken');
    setJwtToken('');
    localStorage.removeItem('pbiToken');
    setPbiToken('');
    setIsAuthenticated(false);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container isOpen={sidebarOpen}>
      <button className="sidebarButton" onClick={ChangeSidebarState}>
        <AiOutlineLeft />
      </button>
      <div className="logoContent">
        <div className="imgContent">
          <img src={theme === 'light' ? logoDark : logoLight} alt="logo.png" />
        </div>
        <h2>Warbush</h2>
      </div>
      {
        linksArray.map(({ icon, label, to }) => (
          <div className="linkContainer" key={label}>
            <NavLink to={to} className={({ isActive }) => `link${isActive ? ` active` : ``}`}>
              <div className="linkIcon">
                {icon}
              </div>
              {
                sidebarOpen && (
                  <span>{label}</span>
                )
              }
            </NavLink>
          </div>
        ))
      }
      <Divider />
      {
        secondaryLinks.map(({ icon, label, to }) => (
          <div className="linkContainer" key={label}>
            <NavLink to={to} className={({ isActive }) => `link${isActive ? ` active` : ``}`}>
              <div className="linkIcon">
                {icon}
              </div>
              {
                sidebarOpen && (
                  <span>{label}</span>
                )
              }
            </NavLink>
          </div>
        ))
      }
      <div className="buttonContainer" key="logout">
        <Button onClick={handleOpen} className={({ isActive }) => `link${isActive ? ` active` : ``}`}>
          <div className="linkIcon">
            <IoIosLogOut />
          </div>
          {
            sidebarOpen && (
              <span>Log-out</span>
            )
          }
        </Button>
      </div>
      <Divider />
      <ThemeContainer className="themeContainer">
        <label class="ui-switch">
          <input type="checkbox" checked={theme === 'dark'} onClick={ChangeTheme} />
          <div class="slider">
            <div class="circle"></div>
          </div>
        </label>
      </ThemeContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
      >
        <DialogContainer>
          <DialogTitle id="alert-dialog-title">
            {"¿Está seguro que desea cerrar sesión?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={logout} autoFocus startIcon={<LogoutIcon />} variant="outlined">
              Salir
            </Button>
          </DialogActions>
        </DialogContainer>
      </Dialog>
    </Container>
  );
};

//#region Data links
const linksArray = [
  // {
  //   label: 'Home',
  //   icon: <AiOutlineHome />,
  //   to: '/',
  // },
  {
    label: 'Dashboards',
    icon: <AiOutlinePieChart />,
    to: '/',
  },
];
const secondaryLinks = [
  {
    label: 'Settings',
    icon: <IoSettingsOutline />,
    to: '/settings',
  },
];
//#endregion

//#region STYLED COMPONENTES
const Container = styled.div`
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.bg};
  position: sticky;
  padding-top: 20px;
  .sidebarButton {
    position: absolute;
    top: ${v.xxlSpacing};
    right: -18px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${(props) => props.theme.bgtgderecha};
    box-shadow: 0 0 4px ${(props) => props.theme.bg3}, 0 0 7px ${(props) => props.theme.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? `initial` : `rotate(180deg)`)};
    border: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: 500px;
    text-align: inherit;
    padding: 0;
    font-family: inherit;
    outline: none;
  }
  .logoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: ${v.lgSpacing};
    .imgContent {
      display: flex;
      img {
        max-width: 100%;
        height: auto;
      }
      cursor: pointer;
      transition: all 0.3s;
      transform: ${({ isOpen }) => (isOpen ? `scale(0.9)` : `scale(0.8)`)};
    }
    h2 {
      display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
    }
  }
  .buttonContainer {
    margin: 8px 0;
    padding: 0 15%;
    :hover {
      background: ${(props) => props.theme.bg3};
      border-radius: 0;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      padding: calc(${v.smSpacing}-2px) 0;
      color: ${(props) => props.theme.text};
      width: 100%;
      :hover {
        background: transparent;
      };
      .linkIcon {
        padding: ${v.smSpacing} ${v.mdSpacing} ${v.smSpacing} ${v.smSpacing};
        display: flex;
        svg {
          font-size: 25px;
        }
      }
    }
  }
  .linkContainer {
    margin: 8px 0;
    padding: 0 15%;
    :hover {
      background: ${(props) => props.theme.bg3};
    }
    .link {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: calc(${v.smSpacing}-2px) 0;
      color: ${(props) => props.theme.text};
      .linkIcon {
        padding: ${v.smSpacing} ${v.mdSpacing};
        display: flex;
        svg {
          font-size: 25px;
        }
      }
      &.active {
        .linkIcon {
          svg {
            color: ${(props) => props.theme.bg4};
          }
        }
        span {
          color: ${(props) => props.theme.bg4};
        }
      }
    }
  }
  .themeContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
    margin: 20px 0px 20px 0px;
    padding: 0 15%;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.bg3};
  margin: ${v.lgSpacing} 0;
`;

const ThemeContainer = styled.div`
  .ui-switch {
    /* switch */
    --switch-bg: rgb(135, 150, 165);
    --switch-width: 48px;
    --switch-height: 20px;
    /* circle */
    --circle-diameter: 32px;
    --circle-bg: rgb(0, 56, 146);
    --circle-inset: calc((var(--circle-diameter) - var(--switch-height)) / 2);
  }

  .ui-switch input {
    display: none;
  }

  .slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: var(--switch-width);
    height: var(--switch-height);
    background: var(--switch-bg);
    border-radius: 999px;
    position: relative;
    cursor: pointer;
  }

  .slider .circle {
    top: calc(var(--circle-inset) * -1);
    left: 0;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    position: absolute;
    background: var(--circle-bg);
    border-radius: inherit;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTkuMzA1IDEuNjY3VjMuNzVoMS4zODlWMS42NjdoLTEuMzl6bS00LjcwNyAxLjk1bC0uOTgyLjk4Mkw1LjA5IDYuMDcybC45ODItLjk4Mi0xLjQ3My0xLjQ3M3ptMTAuODAyIDBMMTMuOTI3IDUuMDlsLjk4Mi45ODIgMS40NzMtMS40NzMtLjk4Mi0uOTgyek0xMCA1LjEzOWE0Ljg3MiA0Ljg3MiAwIDAwLTQuODYyIDQuODZBNC44NzIgNC44NzIgMCAwMDEwIDE0Ljg2MiA0Ljg3MiA0Ljg3MiAwIDAwMTQuODYgMTAgNC44NzIgNC44NzIgMCAwMDEwIDUuMTM5em0wIDEuMzg5QTMuNDYyIDMuNDYyIDAgMDExMy40NzEgMTBhMy40NjIgMy40NjIgMCAwMS0zLjQ3MyAzLjQ3MkEzLjQ2MiAzLjQ2MiAwIDAxNi41MjcgMTAgMy40NjIgMy40NjIgMCAwMTEwIDYuNTI4ek0xLjY2NSA5LjMwNXYxLjM5aDIuMDgzdi0xLjM5SDEuNjY2em0xNC41ODMgMHYxLjM5aDIuMDg0di0xLjM5aC0yLjA4NHpNNS4wOSAxMy45MjhMMy42MTYgMTUuNGwuOTgyLjk4MiAxLjQ3My0xLjQ3My0uOTgyLS45ODJ6bTkuODIgMGwtLjk4Mi45ODIgMS40NzMgMS40NzMuOTgyLS45ODItMS40NzMtMS40NzN6TTkuMzA1IDE2LjI1djIuMDgzaDEuMzg5VjE2LjI1aC0xLjM5eiIgLz4KPC9zdmc+");
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -o-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    ;
  }

  .slider .circle::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    border-radius: inherit;
    -webkit-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
    opacity: 0;
  }

  /* actions */

  .ui-switch input:checked+.slider .circle {
    left: calc(100% - var(--circle-diameter));
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTQuMiAyLjVsLS43IDEuOC0xLjguNyAxLjguNy43IDEuOC42LTEuOEw2LjcgNWwtMS45LS43LS42LTEuOHptMTUgOC4zYTYuNyA2LjcgMCAxMS02LjYtNi42IDUuOCA1LjggMCAwMDYuNiA2LjZ6IiAvPgo8L3N2Zz4=");
  }

  .ui-switch input:active+.slider .circle::before {
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    opacity: 1;
    width: 0;
    height: 0;
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background: ${(props) => props.theme.bgtotal};
  border: 1px solid ${(props) => props.theme.texttertiary};
  color: ${(props) => props.theme.text};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 10px;
  .MuiDialogContentText-root {
    color: ${(props) => props.theme.text};
  }
`;
//#endregion
