import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';
import styled from "styled-components";
import Sidebar from '../components/Sidebar/Sidebar';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

export default function RoutesHandler() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { pbiToken, jwtToken, isAuthenticated, setIsAuthenticated } = useContext(ThemeContext);
  
  useEffect(() => {
    if (pbiToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate('/');
    }
  }, [pbiToken]);

  useEffect(() => {
    if (jwtToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate('/');
    }
  }, [jwtToken]);

  return (
    <>
      {
        isAuthenticated ? (
          <MainContainer className={sidebarOpen ? 'sidebarState active' : 'sidebarState'}>
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <MainRoutes />
          </MainContainer>
        ) : (
          <AuthContainer>
            <AuthenticationRoutes />
          </AuthContainer>
        )
      }
    </>
  );
};



const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
  background: ${({ theme }) => theme.bgtotal};
  transition:all 0.3s ;
  &.active {
    grid-template-columns: 300px auto;
  }
  color:${({ theme }) => theme.text};
`;

const AuthContainer = styled.div`
  height: 100vh;
  width: 100%;
`;
