const API_URL = 'https://datx-backend.onrender.com/api/users';

export const getUserList = async () => {
  try {
    const response = await fetch(`${API_URL}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Error al obtener usuarios');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserDetails = async (id) => {
  try {
    const response = await fetch(`${API_URL}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Error al obtener detalles del usuario');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const createUser = async (user) => {
  try {
    const response = await fetch(`${API_URL}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      throw new Error('Error al crear usuario');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const updateUser = async (user) => {
  try {
    const response = await fetch(`${API_URL}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      throw new Error('Error al actualizar usuario');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const updatePassword = async (id, password) => {
  try {
    const response = await fetch(`${API_URL}/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, password }),
    });
    if (!response.ok) {
      throw new Error('Error al actualizar contraseña');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};