const API_URL = 'https://datx-backend.onrender.com/api/auth';

export const getItemsByUser = async (req) => {
  try {
    const response = await fetch(`${API_URL}/getItemsByUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    if (!response.ok) {
      throw new Error('Error obtener items');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const login = async (req) => {
  try {
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return new Error('Error de conexión con el servidor');;
  }
};

export const verifyToken = async (req) => {
  try {
    const response = await fetch(`${API_URL}/verifyToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const getUser = async () => {
  const jwtToken = localStorage.getItem('jwtToken');

  try {
    const response = await fetch(`${API_URL}/getUser`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      },
    });

    if (response.status === 498) {
      return { Message: 'La sesión ha expirado' };
    }

    const res = await response.json();
    return { ...res, ok: true };
  } catch (error) {
    return error;
  }
};