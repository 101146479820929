const API_URL = 'https://datx-backend.onrender.com/api/powerBi';

export const getEmbedUrl = async (req) => {
  try {
    const response = await fetch(`${API_URL}/getEmbedUrl`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    if (!response.ok) {
      throw new Error('Error al obtener url');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const newItem = async (req) => {
  try {
    const response = await fetch(`${API_URL}/newItem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error('Error al crear nuevo item:', error);
    return error;
  }
};

export const updateItem = async (req) => {
  try {
    const response = await fetch(`${API_URL}/updateItem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error('Error al actualizar item:', error);
    return error;
  }
};

export const deleteItem = async (req) => {
  try {
    const response = await fetch(`${API_URL}/deleteItem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error('Error al eliminar item:', error);
    return error;
  }
};
