import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import Dashboards from "../views/Dashboards";
import Settings from "../views/Settings";

const MainRoutes = ({ }) => {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={<Dashboards />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
};

export default MainRoutes;