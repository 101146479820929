import React from "react";
import styled from "styled-components";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function CustomDelete({ open, handleClose, action, title, element }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
    >
      <Container>
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`¿Está usted seguro que desea eliminar este elemento "${element}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={action} autoFocus startIcon={<DeleteOutlineIcon />} variant="outlined">
            Eliminar
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background: ${(props) => props.theme.bgtotal};
  border: 1px solid ${(props) => props.theme.texttertiary};
  color: ${(props) => props.theme.text};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 10px;
  .MuiDialogContentText-root {
    color: ${(props) => props.theme.text};
  }
`;
