import React from "react";
import styled from "styled-components";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export default function Item({ title, name, setName, isFolder, setIsFolder, reportId, setReportId, action, loading, open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Container>
        <DialogTitle id="alert-dialog-title">
          {title} reporte
        </DialogTitle>
        <DialogContent>
          <div className="input-box">
            <TextField
              label="Título"
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </div>
          <div className="input-box">
            <FormControlLabel
              className="checkboxLabel"
              control={<Checkbox checked={isFolder} onChange={(e) => setIsFolder(e.target.checked)} />}
              label="¿Es carpeta?"
            />
            {
              !isFolder && (
                <TextField
                  label="ID del reporte"
                  variant="standard"
                  value={reportId}
                  onChange={(e) => setReportId(e.target.value)}
                  fullWidth
                />
              )
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="outlined" onClick={action} startIcon={!loading ? <SendIcon /> : <CircularProgress size={24} color="inherit" />}>
            Enviar
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background: ${(props) => props.theme.bgtotal};
  border: 1px solid ${(props) => props.theme.texttertiary};
  color: ${(props) => props.theme.text};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 10px;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .input-box {
    width: 100%;
    height: 20px;
    margin: 30px 0 20px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    .checkboxLabel {
      width: 100%,
    }
    label.Mui-focused {
      color: ${(props) => props.theme.text};
    }
    .MuiInput-underline:after {
      border-bottom-color: ${(props) => props.theme.text};
    }
    .MuiOutlinedInput-root {
      fieldset {
        border-color: ${(props) => props.theme.text};
      }
      &:hover fieldset {
        border-color: ${(props) => props.theme.text};
      }
      &.Mui-focused fieldset {
        border-color: ${(props) => props.theme.text};
      }
    }
    .MuiInputBase-input {
      color: ${(props) => props.theme.text};
    }
    .MuiFormLabel-root {
      color: ${(props) => props.theme.text};
    }
  }
  .button-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      background-color: ${(props) => props.theme.bg4};
      color: ${(props) => props.theme.bgtotal};
      border: 0;
      &:hover {
        background-color: ${(props) => props.theme.bghover};
      }
    }
  }
`;