import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Light, Dark } from './styles/Themes';
import { ThemeProvider } from 'styled-components';
import './assets/styles/styles.scss';
import { getUser, verifyToken } from './api/auth';
import RoutesHandler from './routes/RoutesHandler';

export const ThemeContext = React.createContext(null);

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const themeStyle = theme === 'light' ? Light : Dark;

  const [pbiToken, setPbiToken] = useState(localStorage.getItem('pbiToken'));
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken'));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  async function fetchUser() {
    try {
      const user = await getUser();
      if (!user.ok) {
        setJwtToken('');
      } else {
        setUser(user);
      }
    } catch (err) {
      console.error('Failed to fetch user:', err);
    }
  }
  
  async function verifyIsTokenExists() {
    if (pbiToken) {
      const obj = {
        token: pbiToken,
      }
      const response = await verifyToken(obj);
      if (!response.esValido) {
        localStorage.removeItem('pbiToken');
        setPbiToken('');
      }
    }
  };

  useEffect(() => {
    verifyIsTokenExists();
    fetchUser();
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <>
      <ThemeContext.Provider value={{ theme, setTheme, pbiToken, setPbiToken, isAuthenticated, setIsAuthenticated, user, setUser, jwtToken, setJwtToken }}>
        <ThemeProvider theme={themeStyle}>
          <BrowserRouter>
            <RoutesHandler />
          </BrowserRouter>
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
};

export default App;
