
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, InputAdornment, Checkbox, MenuItem } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { AccountCircle, Email, Password, Save, ExpandMore, ChevronRight, Badge, Security } from "@mui/icons-material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { createUser, updatePassword, updateUser } from "../../api/users";

export default function User({ open, handleClose, userDetails, setResponse, setOpenSnackbar }) {

  const [userInfo, setUserInfo] = useState(null);
  const [userItems, setUserItems] = useState([]);
  const [userRole, setUserRole] = useState('common');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userDetails.length > 0) {
      const info = userDetails.find((e) => e.tabla === 'TBL_USER');
      const items = userDetails.filter((e) => e.tabla === 'TBL_ITEMS');
      const itemsById = items.reduce((acc, item) => {
        acc[item.itemid] = { ...item, children: [] };
        return acc;
      }, {});

      items.forEach(item => {
        if (item.parentid !== null) {
          itemsById[item.parentid].children.push(itemsById[item.itemid]);
        }
      });

      const treeData = Object.values(itemsById).filter(item => item.parentid === null);
      setUserInfo(info);
      if (info) {
        setUserRole(info.userrole);
      }
      setUserItems(treeData);
    } else {
      setUserInfo({});
      setUserItems({});
    }
  }, [userDetails]);

  useEffect(() => {
    if (!open) {
      setErrors({});
    }
  }, [open]);

  const handleCheckChange = (itemId, checked) => {
    const updateUserItems = (items) => {
      return items.map(item => {
        if (item.itemid === itemId) {
          return { ...item, grantaccess: checked, children: updateChildren(item.children, checked) };
        } else if (item.children.length > 0) {
          return { ...item, children: updateUserItems(item.children) };
        } else {
          return item;
        }
      });
    };

    const updateChildren = (children, checked) => {
      return children.map(child => {
        return { ...child, grantaccess: checked, children: updateChildren(child.children, checked) };
      });
    };

    setUserItems(updateUserItems(userItems));
  };

  const handleSave = async () => {
    let errors = {};
    setErrors({});

    const mapUserItems = (items) => {
      return items.flatMap(item => [
        { id: item.itemid, grantaccess: item.grantaccess },
        ...mapUserItems(item.children)
      ]);
    };
    const items = mapUserItems(userItems).filter((e) => e.grantaccess).map((e) => e.id);
    
    if (!userInfo?.username) {
      errors.username = 'El campo Usuario es obligatorio';
    }
    if (!userInfo?.name) {
      errors.name = 'El campo Nombre es obligatorio';
    }
    if (!userInfo?.email) {
      errors.email = 'El campo Email es obligatorio';
    }
    if (!userInfo && !userInfo?.password) {
      errors.password = 'El campo Contraseña es obligatorio';
    }
    if (items.length === 0) {
      errors.items = 'Seleccione una carpeta como mínimo';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);

    const user = {
      id: userInfo.userid,
      username: userInfo.username,
      password: userInfo.password,
      name: userInfo.name,
      lastname: userInfo.lastname,
      email: userInfo.email,
      userrole: userInfo.userrole || userRole,
      userItems: items,
    };

    if (user.id) {
      // Si el usuario tiene un id, entonces es una actualización
      try {
        const updatedUser = await updateUser(user);
        setResponse({ message: updatedUser.Message, severity: updatedUser.Message.toLowerCase().includes('error') ? 'error' : 'success', updateInfo: true });
      } catch (error) {
        // Aquí puedes manejar el error como necesites
        setResponse({ message: error.message, severity: 'error' });
      }
    } else {
      // Si el usuario no tiene un id, entonces es una creación
      try {
        const newUser = await createUser(user);
        // Aquí puedes manejar el nuevo usuario como necesites
        setResponse({ message: newUser.Message, severity: newUser.Message.toLowerCase().includes('error') ? 'error' : 'success', updateInfo: true });
      } catch (error) {
        // Aquí puedes manejar el error como necesites
        setResponse({ message: error.message, severity: 'error' });
      }
    }

    setOpenSnackbar(true);
    setIsLoading(false);
    handleClose();
  };

  const handlePassword = async () => {
    let errors = {};
    setErrors({});
    if (userInfo && !userInfo.password) {
      errors.password = 'El campo Contraseña es obligatorio';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setIsLoadingPassword(true);
    try {
      const updatedPassword = await updatePassword(userInfo.userid, userInfo.password);
      setResponse({ message: updatedPassword.Message, severity: updatedPassword.Message.toLowerCase().includes('error') ? 'error' : 'success' });
    } catch (error) {
      setResponse({ message: error.message, severity: 'error' });
    } finally {
      setOpenSnackbar(true);
      setIsLoadingPassword(false);
    }
  };  

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Container>
        <DialogTitle>
          <h3>{userInfo?.userid ? 'Editar Usuario' : 'Nuevo Usuario'}</h3>
        </DialogTitle>
        <DialogContent>
          <div className="form-section">
            <h4 className="form-subtitle">Información de la cuenta</h4>
            <div className="form-row">
              <div className="input-box">
                <TextField
                  variant="standard"
                  label="Usuario"
                  type="text"
                  value={userInfo ? userInfo.username : ''}
                  onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </div>
              <div className="input-box">
                <TextField
                  variant="standard"
                  label="Contraseña"
                  type="password"
                  value={userInfo ? userInfo.password : ''}
                  onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="input-box">
              <TextField
                select
                label="Perfil"
                value={userRole}
                onChange={(e) => {
                  setUserRole(e.target.value);
                  setUserInfo({ ...userInfo, userrole: e.target.value });
                }}
                variant="standard"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Security />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="admin">admin</MenuItem>
                <MenuItem value="common">common</MenuItem>
              </TextField>
              </div>
            </div>
            {
              userInfo?.userid && (
                <div className="form-row">
                  <Button
                    variant="contained"
                    onClick={handlePassword}
                    startIcon={!isLoadingPassword ? <Save /> : <CircularProgress size={24} color="inherit" />}
                    className="saveBtn"
                    disabled={isLoading || isLoadingPassword}
                  >
                    Cambiar Contraseña
                  </Button>
                </div>
              )
            }
          </div>
          <div className="form-section">
            <h4 className="form-subtitle">Información del usuario</h4>
            <div className="form-row">
              <div className="input-box">
                <TextField
                  variant="standard"
                  label="Nombre"
                  type="text"
                  value={userInfo ? userInfo.name : ''}
                  onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Badge />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </div>
              <div className="input-box">
                <TextField
                  variant="standard"
                  label="Apellido"
                  type="text"
                  value={userInfo ? userInfo.lastname : ''}
                  onChange={(e) => setUserInfo({ ...userInfo, lastname: e.target.value })}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Badge />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="input-box">
                <TextField
                  variant="standard"
                  label="Email"
                  type="mail"
                  value={userInfo ? userInfo.email : ''}
                  onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </div>
            </div>
          </div>
          <div className="form-section">
            <h4 className="form-subtitle">Accesos</h4>
            {errors.items && <p className="items-error">{errors.items}</p>}
            <div className="form-row">
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
              >
                {userItems.length > 0 && userItems.map((item) => (
                  item.isfolder ? (
                    <TreeItem nodeId={item.itemid.toString()} label={
                      <div>
                        <Checkbox checked={item.grantaccess} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheckChange(item.itemid, e.target.checked)} />
                        {item.itemname}
                      </div>
                    }>
                      {item.children.length > 0 && item.children.map((child) => (
                        child.isfolder ? (
                          <TreeItem nodeId={child.itemid.toString()} label={
                            <div>
                              <Checkbox checked={child.grantaccess} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheckChange(child.itemid, e.target.checked)} />
                              {child.itemname}
                            </div>
                          }>
                            {child.children.length > 0 && child.children.map((grandChild) => (
                              <TreeItem nodeId={grandChild.itemid.toString()} label={
                                <div>
                                  <Checkbox checked={grandChild.grantaccess} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheckChange(grandChild.itemid, e.target.checked)} />
                                  {grandChild.itemname}
                                </div>
                              } />
                            ))}
                          </TreeItem>
                        ) : (
                          <TreeItem nodeId={child.itemid.toString()} label={
                            <div>
                              <Checkbox checked={child.grantaccess} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheckChange(child.itemid, e.target.checked)} />
                              {child.itemname}
                            </div>
                          } />
                        )
                      ))}
                    </TreeItem>
                  ) : (
                    <TreeItem nodeId={item.itemid.toString()} label={
                      <div>
                        <Checkbox checked={item.grantaccess} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheckChange(item.itemid, e.target.checked)} />
                        {item.itemname}
                      </div>
                    } />
                  )
                ))}
              </TreeView>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} className="cancelBtn" disabled={isLoading || isLoadingPassword}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={handleSave}
            startIcon={!isLoading ? <SendIcon /> : <CircularProgress size={24} color="inherit" />}
            className="saveBtn"
            disabled={isLoading || isLoadingPassword}
          >
            {userInfo?.userid ? 'Actualizar' : 'Crear'}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background: ${(props) => props.theme.bgtotal};
  border: 1px solid ${(props) => props.theme.texttertiary};
  color: ${(props) => props.theme.text};
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 10px;
  overflow-y: auto;

  .form-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 0;

    .form-subtitle {
      margin-bottom: 20px;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding-bottom: 20px;
    
      .input-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        .checkboxLabel {
          width: 100%,
        }
        label.Mui-focused {
          color: ${(props) => props.theme.text};
        }
        .MuiInput-underline:after {
          border-bottom-color: ${(props) => props.theme.text};
        }
        .MuiOutlinedInput-root {
          fieldset {
            border-color: ${(props) => props.theme.text};
          }
          &:hover fieldset {
            border-color: ${(props) => props.theme.text};
          }
          &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.text};
          }
        }
        .MuiInputBase-input {
          color: ${(props) => props.theme.text};
        }
        .MuiFormLabel-root {
          color: ${(props) => props.theme.text};
        }
        .MuiInputAdornment-root {
          color: ${(props) => props.theme.text};
        }
      }
    }

    .items-error {
      color: #d32f2f;
      font-size: 0.75rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
  }

  .saveBtn {
    background: #3c6e71;
  }
  .saveBtn:hover {
    background: #40CC9E;
  }

  .cancelBtn {
    border-color: #3c6e71;
    color: #3c6e71;
  }
  .cancelBtn:hover {
    border-color: #3c6e71;
    color: #3c6e71;
  }
`;